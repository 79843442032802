import { ClipboardDocumentIcon, LinkIcon } from '@heroicons/react/20/solid'

function ShareMediaLink( props ) {
    const { url } = props;

  return (
    <div className="pt-4">
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-300">
        Share this video
      </label>
      <div className="mt-2 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <LinkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 bg-zinc-900 text-gray-300 ring-1 ring-inset ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder=""
            value={url}
            readOnly
          />
        </div>
        <button
          type="button"
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-gray-700 hover:bg-gray-100"
          onClick={() => { navigator.clipboard.writeText(url)}}
        >
          <ClipboardDocumentIcon className="-ml-0.5 h-5 w-5 text-gray-500" aria-hidden="true" />
          Copy
        </button>
      </div>
    </div>
  )
}

export default ShareMediaLink