const navigation = {
  main: [
    { name: 'About', href: '#' },
    { name: 'Support', href: '#' },
  ],
}

function HomeFooter() {
  return (
    <footer className="bg-zinc-900">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-10 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12 pb-4" aria-label="Footer">
            <img src="slplogowhite.png" className="h-6 opacity-30" />
          {/* {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-zinc-500 hover:text-zinc-200">
                {item.name}
              </a>
            </div>
          ))} */}
        </nav>
        {/* <p className="mt-10 text-center text-sm leading-5 text-zinc-500">
          Sales Learning Tools & Technology.
        </p> */}
      </div>
    </footer>
  )
}

export default HomeFooter