import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react';
import { useState, useEffect } from 'react';
import NavigationBar from './components/NavigationBar';

import Home from './pages/Home'
import Library from './pages/Library'
import SBS from './pages/SBS'
import MAS from './pages/MAS'
import HRO from './pages/HRO'
import GES from './pages/GES'
import Digital from './pages/Digital'
import ESI from './pages/ESI'
// import MyList from './pages/MyList'
// import Play from './pages/Play'
// import NavigationBar from './components/NavigationBar'
import DataTesting from './DataTesting';

function App() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/library' element={<Library />} />
        <Route path='/sbs' element={<SBS />} />
        <Route path='/mas' element={<MAS />} />
        <Route path='/hro' element={<HRO />} />
        <Route path='/ges' element={<GES />} />
        <Route path='/digital' element={<Digital />} />
        <Route path='/esi' element={<ESI />} />
        {/* <Route path='/mylist' element={<MyList />} />
        <Route path='/play' element={<Play />} />
        <Route path='/test' element={<Test />} /> */}
        <Route path='/datatesting' element={<DataTesting />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App