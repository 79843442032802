//import './App.css';
//import {KalturaClient} from 'kaltura-client';
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md' 


function OriginalSeries(props) {

    const { kalturaSession, name, playlistId, toggleModal, index } = props;

  const slideLeft = () => {
    var slider = document.getElementById('slider' + index)
    slider.scrollLeft = slider.scrollLeft - 1500
  }

  const slideRight = () => {
    var slider = document.getElementById('slider' + index)
    slider.scrollLeft = slider.scrollLeft + 1500
  }
  
  return (
    <div className="items-center">
        <p className="text-4xl font-medium text-gray-300 pl-16">{name}</p>
        <div className="relative flex items-center">
            <MdChevronLeft className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideLeft} size={40} />
            <div id={'slider' + index} className="w-full h-{100} overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide p-4">
            <img key="1" onClick={() => toggleModal(true)} src="previewImages/CIOnTheFly.jpg" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            <img key="2" onClick={() => toggleModal(true)} src="previewImages/HalfTimeWithCoachDan.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            <img key="3" onClick={() => toggleModal(true)} src="previewImages/HiPerCurious.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            {/* <img key="4" onClick={() => toggleModal(true)} src="previewImages/LetsTalkAboutRUN.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" /> */}
            <img key="5" onClick={() => toggleModal(true)} src="previewImages/MakeYourMessageMatter.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            {/* <img key="6" onClick={() => toggleModal(true)} src="previewImages/WhatWasThatAbout.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" /> */}
            {/* <img key="7" onClick={() => toggleModal(true)} src="previewImages/LetsTalkAboutWFN.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" /> */}
            <img key="8" onClick={() => toggleModal(true)} src="previewImages/ModernLeaderConnect.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            <img key="9" onClick={() => toggleModal(true)} src="previewImages/toolsTalkV2.png" className="h-48 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
            </div>
            <MdChevronRight className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideRight} size={40} />
        </div>
    </div>
  );
}

export default OriginalSeries;