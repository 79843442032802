import React from 'react';
import { useState, useEffect } from 'react';
import collections from '../data/Collections';

function SwimLaneItem( props ) {

    const { media, toggleModal, collection, playlist } = props;

    function getCollectionForPlaylist(playlistId) {
        var newArr = collections.filter(function (c) {
            return c["playlists"].flatMap((p) => p["playlistId"]).includes(playlistId);
        })

        return newArr.length > 0 ? newArr[0] : "No Collection";
    }

    function durationLabel(seconds) {
        let mins = Math.floor(seconds/60);
        let secs = seconds % 60;
        mins = mins < 10 ? "0" + mins : mins;
        secs = secs < 10 ? "0" + secs : secs;

        return mins + ":" + secs;
    }

    useEffect(() => {
        //console.log(playlist != null ? playlist["name"] : "Nope");
    }, [])

    return (
        // <>
        //     <img key={media["id"]} onClick={() => toggleModal(true, getCollectionForPlaylist(playlist.id), media.id)} src={media["thumbnailUrl"] + '/width/480/height/270/type/3'} className="2xl:h-52 h-32 rounded-md 2xl:mx-3 mx-2 inline-block z-30 hover:z-30 2xl:hover:scale-110 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer" />
        // </>
        <div onClick={() => toggleModal(true, getCollectionForPlaylist(playlist.id), media.id)} className="relative inline-block 2xl:hover:scale-110 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer 2xl:mx-3 mx-2">
            <img src={media["thumbnailUrl"] + '/width/480/height/270/type/3'} className="block 2xl:h-52 h-32 aspect-[16/9] 2xl:rounded-md rounded-sm" />
            <div className="absolute top-0 left-0 right-0 opacity-0 transition ease-in-out duration-500 bg-gradient-to-b from-zinc-900 hover:opacity-90 2xl:h-52 h-32 aspect-[16/9] 2xl:rounded-md rounded-sm">
                <div className="flex flex-col 2xl:h-52 h-32 aspect-[16/9]">
                    <div className="basis-1/2 text-white font-medium 2xl:text-sm text-xs p-4 whitespace-pre-line">
                        {media.name}
                    </div>
                    <div className="basis-1/2 flex items-end text-white font-medium 2xl:text-sm text-xs p-4 whitespace-pre-line">
                        <p className="bg-zinc-900 px-1 rounded-sm">{durationLabel(media.duration)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwimLaneItem