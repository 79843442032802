import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SwimLane from './components/SwimLane';
import EmptyStateSwimLane from './EmptyStateSwimLane';
import collections from './data/Collections';

function DataTesting() {

    const [media, setMedia] = useState([]);
    const [playlists, setPlaylists] = useState([]);

    const playlistIdFilter = "1_sxzohcku,1_bv779c80,1_1sa9fr0z,1_t58lg6vo,1_8kmv4ads,1_b1rth3f1,1_z1oh8ikk";

    const ks = "djJ8MTkyMjgzMXzROaeNFhINYjQ2Kd3n2F_PDIZxQZw2BSP0telY_hZYUfL6leyLHkDW4OosUK50DeJXWxTnB9hucDiDRT2yTexD7fI63C5kGg0cspMnOb7KbebMQfRclC3MWw3yKKsRqX1jHEp4Jsf20rInw1SJ4kkzi_JoBTTFmr4E-90UJh0S-w==";

    

    const [allPlaylistContent, setAllPlaylistContent] = useState([]);

    const getPlaylists = async (ks) => {

        const pageSize="500";

        //axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=1_t58lg6vo,1_8kmv4ads,1_sxzohcku,1_bv779c80&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=' + pageSize)
        axios.post('https://www.kaltura.com/api_v3/service/playlist/action/list?ks=' + ks + '&format=1&filter[objectType]=KalturaMediaEntryFilter&filter[idIn]=' + playlistIdFilter + '&filter[categoriesFullNameIn]=Sales Learning Plus&pager[objectType]=KalturaPager&pager[pageSize]=' + pageSize)
        .then(res => {
        const json = res.data;
        setPlaylists(json["objects"]);
        let playlistContent = json["objects"].flatMap((pl) => pl["playlistContent"].split(','));
        setAllPlaylistContent(playlistContent);
        console.log(allPlaylistContent);
        //getMedia(ks)
        })
    }

    const getMedia = async (ks) => {

        const pageSize="500";

        axios.post('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + ks + '&format=1&pager[objectType]=KalturaPager&pager[pageSize]=' + pageSize + '&filter[objectType]=KalturaMediaEntryFilter&filter[categoriesFullNameIn]=Sales Learning Plus')
        .then(res => {
        const json = res.data;
        setMedia(json["objects"]);
        // let filteredMedia = json["objects"].filter(function (m) {
        //     return allPlaylistContent.includes(m.id);
        // })
        //setMedia(filteredMedia);
        //setMedia(json["objects"].filter(function (m) { return allPlaylistContent.includes(m.id) }))
        })
    }

    const [library, setLibrary] = useState();
    



    function getMediaWithId(id) {
        var episode = media.find(ep => {
            return ep.id === id
        });

        //console.log(episode);
    }

    function getPlaylistWithId(id) {
        var playlist = playlists.find(pl => {
            return pl.id === id
        });
        console.log(playlist);
        return playlist;
    }

    //const playlistContent = "1_t7jeetm1,1_kou76w0n,1_30i2c0w2,1_gyucwzbq,1_sbawidas,1_ccu1vgtq,1_2nrqp22z,1_s6uyzaxx";

    function getMediaForPlaylist(playlistContent) {
        const idArray = playlistContent.split(',');
        console.log('TEST123: ' + idArray)
        idArray.map((x) => {
            //console.log(x);
        })

        var playlistMedia = media.filter(function (ep) {
            return idArray.includes(ep.id);
        });

        //console.log(playlistMedia);
        return playlistMedia;
    }

    const [allPlaylistIds, setAllPlaylistIds] = useState([]);

    function getPlaylistForEpisode(episodeId) {
        var newArr = playlists.filter(function (pl) {
            return pl["playlistContent"].includes(episodeId);
        });

        //return newArr.length > 0 ? newArr[0]["name"] : "No Playlist";
        return newArr.length > 0 ? newArr[0] : { "name": "No Playlist" };
    }

    function getCollectionForPlaylist(playlistId) {
        var newArr = collections.filter(function (c) {
            return c["playlists"].flatMap((p) => p["playlistId"]).includes(playlistId);
        })

        return newArr.length > 0 ? newArr[0] : "No Collection";
    }

    // function getCollectionForPlaylist(playlistId) {
    //     var collection = collections.find(c => {
    //         var newArr = c["playlists"].flatMap((p) => p["playlistId"]);
    //         return newArr.includes(playlistId);
    //     })

    //     return collection;
    // }

    function testFlatMap() {
        let newArr = collections.flatMap((c) => c["playlists"].flatMap((p) => p["playlistId"]));
        console.log('testFlatMap: ' + newArr);
        setAllPlaylistIds(newArr);
    }

    function filteredMedia() {
        return media.filter(function (m) {
            return allPlaylistContent.includes(m.id);
        })
    }

    useEffect(() => {
        getMedia(ks);
        getPlaylists(ks);
        //getMediaForPlaylist(getPlaylistWithId("1_8kmv4ads")["playlistContent"]);
        //console.log('getPlaylistWithId: ' + getPlaylistWithId("1_8kmv4ads"));
        // if (playlists.length > 0) {
        //     getPlaylistWithId("1_8kmv4ads");
        // }
        // getMediaWithId("1_t7jeetm1");
        testFlatMap();
    }, [])

    if (!playlists) {
        console.log('No Playlists')
        return <p>No Playlists</p>;
    }

    return (
        <div className="grid grid-cols-1 items-center p-10">
            <p className="text-3xl text-white font-medium pb-10">Kaltura API Testing</p>
            <div className="grid grid-cols-3">
                <div>
                    <p className="text-md text-gray-300 font-medium">Episode</p>
                </div>
                <div className="items-end text-gray-300 font-medium text-md">
                    Playlist
                </div>
                <div className="items-end text-gray-300 font-medium text-md">
                    Collection
                </div>
            </div>
            {
                filteredMedia().map((episode) => (
                    <div key={episode.id} className="grid grid-cols-3">
                        <div>
                            <p className="text-sm text-white">{episode.name}</p>
                        </div>
                        <div className="items-end text-white text-sm">
                            {getPlaylistForEpisode(episode.id)["name"]}
                        </div>
                        <div className="items-end text-white text-sm">
                            {getCollectionForPlaylist(getPlaylistForEpisode(episode.id)["id"])["name"]}
                        </div>
                    </div>
                ))
            }
            {/* { playlists.length > 0 && media.length > 0 ? <SwimLane playlist={getPlaylistWithId('1_8kmv4ads')} media={getMediaForPlaylist(getPlaylistWithId('1_8kmv4ads')['playlistContent'])} /> : <p>No Playlist</p> }
            <EmptyStateSwimLane /> */}
            <div className="relative">
                <img src="previewImages/HalfTimeWithCoachDan.png" className="block h-52 aspect-[16/9]" />
                <div className="absolute top-0 left-0 bottom-0 right-0 opacity-0 transition ease-in-out duration-500 bg-purple-800 hover:opacity-100 h-52 aspect-[16/9]">
                    <div className="absolute text-white text-md">
                        Hello World
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataTesting