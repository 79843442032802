import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { XCircleIcon } from '@heroicons/react/20/solid'
import ShareMediaLink from "../components/ShareMediaLink"
import SeasonDropdown from "../components/SeasonDropdown"

function EpisodeList(props) {

  const { kalturaSession, collection, media, playlists, episodeId, onClose } = props;

    const [episodes, setEpisodes] = useState([]);
    const [selectedEpisode, setSelectedEpisode] = useState(episodeId);

    const getPlaylist = async () => {
      //console.log('getPlaylist');
      axios.get('https://www.kaltura.com/api_v3/service/playlist/action/get?ks=' + kalturaSession + '&id=' + selectedPlaylist + '&format=1')
      .then(res => {
        const json = res.data;
        let playlistContent = json["playlistContent"];
        getEpisodes(playlistContent);
      })
    }

    function getPlaylistWithId(id) {
        var playlist = playlists.find(pl => {
            return pl.id === id
        });
        //console.log(playlist);
        return playlist;
    }

    const getEpisodes = async ( playlistContent ) => {
      //console.log('getEpisodes');
      //console.log('Kaltura Session: ' + kalturaSession)
    axios.get('https://www.kaltura.com/api_v3/service/media/action/list?ks=' + kalturaSession + '&format=1&filter[idIn]=' + playlistContent + '&filter[objectType]=KalturaMediaEntryFilter')
    .then(res => {
      const json = res.data;
      //console.log('EpisodeList Test123');
      //console.log(json);
      setEpisodes(json["objects"]);
    });
  }

  function durationLabel(seconds) {
    let mins = Math.floor(seconds/60);
    let secs = seconds % 60;
    mins = mins < 10 ? "0" + mins : mins;
    secs = secs < 10 ? "0" + secs : secs;

    return mins + ":" + secs;
  }

  function sortBySeasonEpisode(a, b) {
    if (a.description.slice(0,6) < b.description.slice(0,6)) {
      return -1;
    }

    if (a.description.slice(0,6) > b.description.slice(0,6)) {
      return 1;
    }

    return 0;
  }

  const [selectedPlaylist, setSelectedPlaylist] = useState(collection["playlists"][0]["playlistId"]);

  //const url = 'https://cdnapisec.kaltura.com/p/1922831/sp/192283100/embedIframeJs/uiconf_id/52279042/partner_id/1922831?iframeembed=true&playerId=kaltura_player_1679422331&entry_id=' + selectedEpisode + '&ks=' + kalturaSession
  const url = 'https://cdnapisec.kaltura.com/p/1922831/embedPlaykitJs/uiconf_id/52485442?iframeembed=true&entry_id=' + selectedEpisode + '&ks=' + kalturaSession
  //const url = 'https://cdnapisec.kaltura.com/p/1922831/embedPlaykitJs/uiconf_id/55956032?iframeembed=true&entry_id=' + selectedEpisode + '&ks=' + kalturaSession
  /*52485442*/

  //const url2 = 'https://cdnapisec.kaltura.com/p/1922831/embedPlaykitJs/uiconf_id/52485442?iframeembed=true&entry_id='
  //{embedBaseUrl}/p/{partnerId}/embedPlaykitJs/uiconf_id/{uiConfId}?iframeembed=true&entry_id={entryId}

  function getMediaForPlaylist(playlistContent) {
      const idArray = playlistContent.split(',');
      //console.log('TEST123: ' + idArray)
      idArray.map((x) => {
          //console.log(x);
      })

      var playlistMedia = episodes.filter(function (ep) {
          return idArray.includes(ep.id);
      });

      //console.log(playlistMedia);
      return playlistMedia;
  }

  function getMediaWithId(id) {
        var episode = media.find(ep => {
            return ep.id === id
        });

        //console.log(episode);
        return episode;
    }

  function handleEpisodeSelected(episodeId) {
    setSelectedEpisode(episodeId);
    const element = document.getElementById('modalContainer');
    if(element) {
      element.scrollIntoView( { behavior: 'smooth' } );
    }
  }

  //const handleSelectSeason = () => setShowModal(false)
  function handleSelectSeason(playlistId) {
    //console.log("handleSelectSeason was called!")
    setSelectedPlaylist(playlistId);
  }

  function getPlaylistForEpisode(episodeId) {
      var newArr = playlists.filter(function (pl) {
          return pl["playlistContent"].includes(episodeId);
      });

      //return newArr.length > 0 ? newArr[0]["name"] : "No Playlist";
      return newArr.length > 0 ? newArr[0] : { "name": "No Playlist" };
  }

  function getSeasonName(episodeId) {
    var seasonName = "Select Season"
    for (let i = 0; i < collection["playlists"].length; i++) {
      if (collection[i]["playlistId"] = getPlaylistForEpisode(episodeId)["id"]) {
        return collection[i]["name"]
      }
    }

    return seasonName
  }

  useEffect(() => {
    //etPlaylist();
    //getPlaylistWithId(selectedPlaylist);
    //console.log('initial episodeId: ' + episodeId);
  }, [kalturaSession, selectedPlaylist]);

  return (
    <div className="grid grid-cols-1 bg-zinc-900 p-4" id="modalContainer">
      <div className="">
        {/* <p className="2xl:text-2xl text-xl font-medium text-white">{collection["name"]}</p> */}
        <div className="grid grid-cols-2 items-center">
          <div><p className="2xl:text-2xl text-xl font-medium text-white">{collection["name"]}</p></div>
          <div className="grid justify-items-end"><button onClick={onClose}><XCircleIcon className="w-8 h-8 text-white" /></button></div>
        </div>
        <p className="2xl:text-lg text-sm font-medium text-gray-300 pb-4">{getMediaWithId(selectedEpisode)["name"]}</p>
        {/* <img src={collection["thumbnail"]} alt="" /> */}
        <div className="flex justify-center">
            <iframe id="kaltura_player_1679422331" className="w-full aspect-[16/9]" src={url} allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" frameBorder="0"></iframe>
        </div>
      </div>
      <div className="grid grid-cols-1 bg-zinc-900">
      <ShareMediaLink url={'https://www.slplus.tv?media=' + selectedEpisode} />
      <div className="flex items-center md:gap-10 md:pt-4">
      {/* <div className="grid grid-cols-1"> */}
        {/* <p className="2xl:text-xl text-lg font-medium text-white px-4 pt-4">Episodes</p> */}
        {/* {collection["playlists"].map((pl) => (
          <p className="2xl:text-lg text-md text-white hover:cursor-pointer pt-4" onClick={() => setSelectedPlaylist(pl["playlistId"])} key={pl["playlistId"]}>{pl["name"]}</p>
        ))} */}
        <SeasonDropdown collection={collection} onSelect={handleSelectSeason} selectedPlaylistName="Select Season" />
      </div>
    <table className="min-w-full divide-y divide-gray-300">
      <tbody className="px-4">
        {/* {episodes?.map((episode) => ( */}
        {getPlaylistWithId(selectedPlaylist)["playlistContent"].split(',').map((episodeId) => (
          <tr key={episodeId} className="hover:cursor-pointer" onClick={() => handleEpisodeSelected(episodeId)}>
            <td className="py-4 text-sm font-medium text-gray-900">
              <div key={episodeId} className={"flex py-4 px-4 hover:bg-zinc-800 rounded-md " + ( selectedEpisode == episodeId ? 'bg-zinc-800' : 'bg-zinc-900')}>
                <img className="2xl:h-24 h-16 rounded-sm" src={getMediaWithId(episodeId).thumbnailUrl + '/width/240/height/135/type/3'} alt="" />
                <div className="ml-3 w-full">
                  <div className="flex items-center justify-between">
                    <p className="2xl:text-sm text-xs font-medium text-gray-300">{getMediaWithId(episodeId).name}</p>
                    <p className="2xl:text-sm text-xs text-gray-300">{durationLabel(getMediaWithId(episodeId).duration)}</p>
                  </div>
                  <div className="flex flex-cols-2 items-center justify-between">
                    <div className="2xl:text-sm text-xs text-gray-500">
                    {
                      getMediaWithId(episodeId).description.length > 1 ? getMediaWithId(episodeId).description.replace(/(<([^>]+)>)/gi, "") : <p></p>
                    }
                    </div>
                    {/* <div className="bg-zinc-700 text-sm text-gray-300 min-w-fit content-end">{episode.views} views</div> */}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </div>
  )
}

export default EpisodeList
