import React from 'react';
import { useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import SwimLaneItem from './SwimLaneItem';

function SwimLane( props ) {
    const { name, playlist, media, index, toggleModal, collection } = props;

    function getMediaWithId(id) {
        var episode = media.find(ep => {
            return ep.id === id
        });

        return episode;
    }

    useEffect(() => {
    }, [])

    const slideLeft = () => {
        var slider = document.getElementById('slider' + index)
        slider.scrollLeft = slider.scrollLeft - 1500
    }

    const slideRight = () => {
        var slider = document.getElementById('slider' + index)
        slider.scrollLeft = slider.scrollLeft + 1500
    }

    return (
        <div className="items-center">
            <p className="2xl:text-4xl text-2xl font-medium text-gray-100 pl-16">{name}</p>
            <div className="relative flex items-center">
                <MdChevronLeft className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideLeft} size={40} />
                <div id={'slider' + index} className="w-full h-{100} overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide p-4">
                {playlist["playlistContent"].split(',').reverse().map((mediaId) => (
                    <SwimLaneItem key={mediaId} media={getMediaWithId(mediaId)} toggleModal={toggleModal} playlist={playlist} />
                ))}
                </div>
                <MdChevronRight className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideRight} size={40} />
            </div>
        </div>
    )
}
export default SwimLane