import React from 'react'
import EpisodeList from './EpisodeList'

function ModalVideoInfo({ visible, onClose, kalturaSession, collection, media, playlists, episodeId }) {

    const handleOnClose = (e) => {
        if(e.target.id === "container") onClose();
    }

    if (!visible) return null;

    return (
        <div id="container" onClick={handleOnClose} className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur-sm z-50 flex justify-center items-center">
        <div className="rounded-lg 2xl:w-1/3 2xl:h-3/4 w-2/5 h-5/6 overflow-y-auto scrollbar-hide">
            <EpisodeList kalturaSession={kalturaSession} collection={collection} media={media} playlists={playlists} episodeId={episodeId} onClose={onClose} />
        </div>
            {/* <div className="grid grid-cols-2 bg-zinc-800 rounded-lg w-1/2">

                <div>
                <img src="thumbnails/MakeYourMessageMatter.png" className="w-full rounded-t-lg" />
                <div>
                    <div className="grid grid-cols-3 gap-4 my-10">
                        <div className="px-4 col-span-2">
                            <p className="text-sm font-medium text-gray-100">Make Your Message Matter</p>
                            <p className="text-sm text-zinc-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget vestibulum ligula, ut varius dui. Donec congue tempor eros non maximus. Donec tincidunt erat fringilla lectus ultricies aliquam. Aliquam mauris neque, vehicula in quam eu, venenatis vehicula urna.</p>
                        </div>
                        <div className="text-sm">
                            <p className="text-gray-100 text-medium">Creator</p>
                            <p className="text-zinc-400">Corporate Visions</p>
                            <p className="text-gray-100 text-medium pt-4">Categories</p>
                            <p className="text-zinc-400">Sales Skills, CVI</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className="bg-zinc-900">
                <h1 className="text-2xl font-medium text-white px-4 pt-4">Episodes</h1>
                    <EpisodeList />
                </div>

            </div> */}
        </div>
    )
}

export default ModalVideoInfo