import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> */}
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-base font-medium text-gray-200">
          Select Business Unit
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-200" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/* <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-zinc-800 text-gray-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/sbs"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  SBS
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/mas"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  MAS
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/hro"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  HRO
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/ges"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  GES
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/digital"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Digital
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/esi"
                  className={classNames(
                    active ? 'bg-zinc-900 text-gray-200' : 'text-gray-400',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  ESI
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
