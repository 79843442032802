const collections = [
    {
      "name": "Leading Ladies of ADP",
      "thumbnail": "previewImages/Channel Leading Ladies of ADP Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_wiw4aodb"
          }
      ]
  },
    {
      "name": "SL+ Live the Pod",
      "thumbnail": "previewImages/Channel Sales Learning+ Live - The Pod Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_qail1b0a"
          }
      ]
  },
     {
      "name": "Lead with Tech & AI",
      "thumbnail": "previewImages/Channel Lead with Tech & AI Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_7v4cs7ys"
          }
      ]
  },
  {
      "name": "Dialogues au sommet",
      "thumbnail": "previewImages/Channel Dialogues au sommet Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_vrxkarf4"
          }
      ]
  },
  {
      "name": "The Sales Conversation with Corporate Visions",
      "thumbnail": "previewImages/Channel The Sales Conversation with Corporate Visions Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_452bqkf7"
          }
      ]
  },
  {
      "name": "Behind the Desk",
      "thumbnail": "previewImages/Channel Behind the Desk Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_mr7yvgsl"
          },
          {
              "name": "Season 2",
              "playlistId": "1_zzsi43mt"
          }
      ]
  },
    {
      "name": "PodLife",
      "thumbnail": "previewImages/Channel PODLife Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_eezw6ruy"
          },
          {
              "name": "Season 2",
              "playlistId": "1_28z24ujp"
          }
      ]
  },
    {
      "name": "Sales Learning Plus Update",
      "thumbnail": "previewImages/SLPlusUpdateThumbnail.jpg",
      "playlists": [
          {
              "name": "FY24",
              "playlistId": "1_wziofwq1"
          }
      ]
  },
    {
        "name": "Senior Leader Top Picks",
        "thumbnail": "previewImages/Top Picks Show Thumbnail.jpg",
        "playlists": [
            {
                "name": "Laci Buzelli",
                "playlistId": "1_4wlpkuxp"
            }
        ]
  },
  {
      "name": "Best of BPO",
      "thumbnail": "previewImages/Channel Best in BPO Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_1dqq3i3q"
          }
      ]
  },
  {
      "name": "MAS TV",
      "thumbnail": "previewImages/MAS TV Thumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_w1qtb15f"
          },
          {
              "name": "Season 2",
              "playlistId": "1_0wdr77f4"
          }
      ]
  },
  {
      "name": "Let's Get Digital",
      "thumbnail": "previewImages/letsgetdigitalshowthumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_3tkqxd53"
          }
      ]
  },
  {
      "name": "Objection to Opportunity",
      "thumbnail": "previewImages/Channel Objection to Opportunity Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_8w3uqiru"
          }
      ]
  },
  {
      "name": "Global Entry",
      "thumbnail": "previewImages/Channel Global Entry Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_lxvj66bu"
          }
      ]
  },
  {
      "name": "Reference Ready",
      "thumbnail": "previewImages/ReferenceReadyThumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_nbr391zn"
          }
      ]
  },
  {
      "name": "Raising the Bar with Brokers",
      "thumbnail": "previewImages/Channel Raising the Bar with Brokers Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_artiiqrc"
          }
      ]
  },
  {
      "name": "Managed Services Shorts",
      "thumbnail": "previewImages/Channel Managed Services Shorts Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_9gvxxk77"
          }
      ]
  },
  {
      "name": "Cracking the Code with JJ the CPA",
      "thumbnail": "previewImages/Channel Cracking the Code with JJ the CPA Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_peqar7zu"
          }
      ]
  },
  {
      "name": "PreSales Integration",
      "thumbnail": "previewImages/Channel PreSales Integration Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_7gyugv9b"
          }
      ]
  },
  {
      "name": "Win It Right",
      "thumbnail": "previewImages/WinItRightShowThumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_m14m0wrv"
          }
      ]
  },
  {
      "name": "Revenue Fuel",
      "thumbnail": "previewImages/RevenueFuelShowThumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_2ucze55f"
          },
          {
              "name": "Season 2",
              "playlistId": "1_yoj3yhob"
          }
      ]
  },
  {
      "name": "Q4",
      "thumbnail": "previewImages/q4showthumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_f4p17xa0"
          }
      ]
  },
  {
      "name": "Qualification with MEDDICC",
      "thumbnail": "previewImages/MEDDICC Thumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_i2ksdzjt"
          },
          {
              "name": "Season 2",
              "playlistId": "1_8krfol12"
          }
      ]
  },
  {
      "name": "What The Tech!?",
      "thumbnail": "previewImages/What the Tech Series thumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_78eg4g7b"
          }
      ]
  },
  {
      "name": "Gotta Minute",
      "thumbnail": "previewImages/gotta minute show thumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_m75ds47v"
          }
      ]
  },
  {
      "name": "CI Avatars",
      "thumbnail": "previewImages/CI_Avatars_Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_1d2ujv9u"
          }
      ]
  },
  {
        "name": "Workforce Newsroom Spotlight",
        "thumbnail": "previewImages/Spotlight Title.png",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_19bjuagq"
            }
        ]
  },
  {
        "name": "Sales Learning Plus Live",
        "thumbnail": "previewImages/SLPlusLiveShowThumbnail.jpg",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_96onzy8c"
            },
            {
                "name": "Season 2",
                "playlistId": "1_2d8t8b3s"
            }
        ]
  },
  {
        "name": "Leader Updates",
        "thumbnail": "previewImages/leaderupdatesthumbnail.jpg",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_m4764auh"
            }
        ]
  },
  {
        "name": "SBS Elite Perspective",
        "thumbnail": "previewImages/sbseliteperspective.jpg",
        "playlists": [
            {
                "name": "Jeremy Dyer",
                "playlistId": "1_1ecn6mbt"
            },
            {
                "name": "Stacy Bolser",
                "playlistId": "1_7lp10jzu"
            },
            {
                "name": "Jenee Nentwig",
                "playlistId": "1_r0rgpjp7"
            }
        ]
  },
    {
        "name": "HRO Canada in 5",
        "thumbnail": "previewImages/HRO Canada in 5 Thumbnail.png",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_mqftifgq"
            },
            {
                "name": "Season 2",
                "playlistId": "1_ignr0wfy"
            }
        ]
  },
    {
        "name": "What's Important Now for Sales",
        "thumbnail": "previewImages/WINS Show Thumbnail.jpg",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_sopda4fd"
            }
        ]
  },
    {
        "name": "Competitive Success - The 101",
        "thumbnail": "previewImages/CompetitiveSuccess_Thumbnail.jpg",
        "playlists": [
            {
                "name": "Season 1",
                "playlistId": "1_92xlit3i"
            },
            {
                "name": "Season 2",
                "playlistId": "1_1kueex7i"
            }
        ]
  },
  {
    "name": "Make Your Message Matter",
    "thumbnail": "previewImages/MakeYourMessageMatter.png",
    "playlists": [
      {
        "name": "Season 1",
        "playlistId": "1_t58lg6vo"
      },
      {
        "name": "Season 2",
        "playlistId": "1_8kmv4ads"
      },
      {
          "name": "Season 3",
          "playlistId": "1_ao13z6kl"
      }
    ]
  },
  {
    "name": "CI on the Fly",
    "thumbnail": "previewImages/CIOnTheFly.jpg",
    "playlists": [
      {
        "name": "Season 1",
        "playlistId": "1_sxzohcku"
      },
      {
        "name": "Season 2",
        "playlistId": "1_bv779c80"
      },
      {
        "name": "Season 3",
        "playlistId": "1_rx8esl23"
      }
    ]
  },
  {
    "name": "HiPer Curious",
    "thumbnail": "previewImages/HiPerCurious.png",
    "playlists": [
        {
            "name": "Season 1",
            "playlistId": "1_1sa9fr0z"
        },
        {
            "name": "Season 2",
            "playlistId": "1_dokkeujm"
        }
    ]
  },
  {
      "name": "Tool Talk",
      "thumbnail": "previewImages/ToolsTalkV2.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_z1oh8ikk"
          }
      ]
  },
  {
      "name": "Half Time With Coach Dan",
      "thumbnail": "previewImages/HalfTimeWithCoachDan.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_7v9rye8c"
          }
      ]
  },
  {
      "name": "Modern Leader Connect",
      "thumbnail": "previewImages/ModernLeaderConnect.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_b1rth3f1"
          }
      ]
  },
  {
      "name": "Genuinely Curious Presents",
      "thumbnail": "previewImages/GC Presents.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_x8xhe2lb"
          },
          {
              "name": "Season 2",
              "playlistId": "1_ttnnf5p6"
          },
          {
              "name": "Season 3",
              "playlistId": "1_6ipvl792"
          },
          {
              "name": "Season 4",
              "playlistId": "1_0urc0pe9"
          },
          ,
          {
              "name": "Season 5",
              "playlistId": "1_qdl0z86g"
          }
      ]
  },
  {
      "name": "LeaderFlix",
      "thumbnail": "previewImages/LeaderFlixWidescreen.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_cn52itun"
          },
          {
              "name": "Season 2",
              "playlistId": "1_sr973vbx"
          },
          {
              "name": "Season 3",
              "playlistId": "1_0gmhccos"
          },
          {
              "name": "Season 4",
              "playlistId": "1_p2b3zuue"
          },
          {
              "name": "Season 5",
              "playlistId": "1_5t9zu1ww"
          }
      ]
  },
  {
      "name": "Thought Leadership",
      "thumbnail": "previewImages/Thumbnail_ThoughtLeadership.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_1twn39ze"
          }
      ]
  },
  {
      "name": "Main Street Macro",
      "thumbnail": "previewImages/MSM Logo.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_den2ighe"
          }
      ]
  },
  {
      "name": "Workforce News Minute",
      "thumbnail": "previewImages/WFNM Logo.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_8pslyeh9"
          }
      ]
  },
  {
      "name": "Win It In A Minute",
      "thumbnail": "previewImages/WinItInAMinute Thumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_etj6a8wi"
          },
          {
              "name": "Season 2",
              "playlistId": "1_frojej82"
          },
          {
              "name": "Season 3",
              "playlistId": "1_o7bzs7af"
          }
      ]
  },
  {
      "name": "Let's Talk WFN",
      "thumbnail": "previewImages/LetsTalkAboutWFN.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_o18uslgx"
          }
      ]
  },
  {
      "name": "Let's Talk RUN",
      "thumbnail": "previewImages/LetsTalkAboutRUN.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_e3nh1fws"
          }
      ]
  },
  {
      "name": "What Was THAT About",
      "thumbnail": "previewImages/WWTA_ShowThumbnail.png",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_obrrq4wx"
          }
      ]
  },
  {
      "name": "Power Generation",
      "thumbnail": "previewImages/PowerGenerationShowThumbnail.jpg",
      "playlists": [
          {
              "name": "Season 1",
              "playlistId": "1_fc0bozy2"
          }
      ]
  }
]

export default collections;