import React from 'react';
import { useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

function SwimLaneEmptyState( props ) {

    const { name } = props;

    useEffect(() => {
    }, [])

    const slideLeft = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft - 1500
    }

    const slideRight = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft + 1500
    }

    return (
        <div className="items-center">
            <p className="text-4xl font-medium text-gray-100 pl-16">{name}</p>
            <div className="relative flex items-center">
                <MdChevronLeft className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideLeft} size={40} />
                <div id={'slider'} className="w-full h-{100} overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide p-4">
                <div className="h-48 aspect-[16/9] bg-zinc-800 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer"></div>
                <div className="h-48 aspect-[16/9] bg-zinc-800 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer"></div>
                <div className="h-48 aspect-[16/9] bg-zinc-800 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer"></div>
                <div className="h-48 aspect-[16/9] bg-zinc-800 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer"></div>
                <div className="h-48 aspect-[16/9] bg-zinc-800 rounded-md mx-3 inline-block z-30 hover:z-40 hover:scale-105 transform transition duration-300 hover:drop-shadow-lg hover:cursor-pointer"></div>
                </div>
                <MdChevronRight className="opacity-50 fill-white cursor-pointer hover:opacity-100" onClick={slideRight} size={40} />
            </div>
        </div>
    )
}
export default SwimLaneEmptyState