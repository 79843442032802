import { Fragment } from 'react'
import { Popover, Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Login } from '@microsoft/mgt-react';

import BusinessUnitDropdown from '../components/BusinessUnitDropdown'

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <Popover className="relative bg-gradient-to-b from-zinc-900">
      <div className={`z-50 flex items-center justify-between p-6 md:justify-start md:space-x-10 ${window.location.pathname == '/play' ? "hidden" : ""}`}>
        <div>
          <a href="#" className="flex">
            <span className="sr-only">My Sales Learning</span>
            <img
              className="h-8 w-auto sm:h-10"
            //   src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            src="slplogo.png"
              alt="Sales Learning +"
            />
          </a>
        </div>
        <div className="-my-2 -mr-2 md:hidden">
          {/* <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button> */}
        </div>
        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            <div className="flex items-center space-x-6">
              <a href="/" className="text-base font-medium text-gray-200 hover:text-white">
                Home
              </a>
              <a href="/library" className="text-base font-medium text-gray-200 hover:text-white">
                Browse
              </a>
              <BusinessUnitDropdown />
            </div>
          </Popover.Group>
          <div className="flex items-center md:ml-12 mgt-light rounded">
            <Login />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="slpluslogo.png"
                    alt="Sales Learning +"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-1 gap-4">
                {/* Links for mobile navigation go here */}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
